@import './reset.css';

h1,
h2,
h3 {
  text-transform: uppercase;
}

p {
  font-size: 20;
}
